/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/imagesloaded@4.1.4/imagesloaded.pkgd.min.js
 * - /npm/autosize@4.0.2/dist/autosize.min.js
 * - /npm/moment-timezone@0.5.26/builds/moment-timezone-with-data-10-year-range.min.js
 * - /npm/choices.js@3.0.3/assets/scripts/dist/choices.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
